import { roundToNearestMinutesWithOptions } from "date-fns/fp";
import React from "react";

class PageTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: `Hallo! \n Ik ben Ido`,
    };
  }

  render() {
    function NewlineText(props) {
      let startDelay = 0.5;
      let triggerDelay = 10;

      if (props.text.animation) {
        startDelay = 0;
        triggerDelay = 0;

        return (
          <span aria-hidden="true" style={{ animation: "" }} key={"homepage"}>
            {props.text.displayText}
          </span>
        );
      }

      if (!props.text.delay) {
        startDelay = props.text.delay;
      }
      const text = props.text.displayText;
      let index = 0;
      let newText = text.split("\n").map((item, i) => {
        let elements = item.split("").map((character, l) => {
          let customStyleClass = null;
          if (props.animation) {
          } else {
            customStyleClass = "noAnimation";
          }
          index++;

          console.log(customStyleClass);
          return (
            <span
              aria-hidden="true"
              style={{
                "animation-delay": startDelay + index / triggerDelay + "s",
              }}
              key={i + "title"}
              className={customStyleClass}
            >
              {character}
            </span>
          );
        });
        return (
          <span key={i}>
            {elements} <br />
          </span>
        );
      });
      // this.setS
      return newText;
    }

    return (
      <div id="portfolio">
        <h1 className="title">
          <NewlineText text={this.props} />
        </h1>
      </div>
    );
  }
}
export default PageTitle;
