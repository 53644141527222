import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import Footer from "../../Components/Footer/Footer.jsx"

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: `Hallo! \n Ik ben Ido`,
    };
  }

  render() {
    function NewlineText(props) {
      const text = props.text;
      let index = 0;
      let newText = text.split("\n").map((item, i) => {
        let elements = item.split("").map((character, l) => {
          index++;
          return (
            <span
              aria-hidden="true"
              style={{ "animation-delay": 0.5 + index / 10 + "s" }}
              key={i + "homepage"}
            >
              {character}
            </span>
          );
        });
        return (
          <span key={i}>
            {elements} <br />
          </span>
        );
      });
      return newText;
    }

    return (
      <div >
        <div id="home">
          <h1 className="title">
            {/* <PageTitle displayText="Hallo, \n Ik ben Ido" /> */}
            <NewlineText text={"Hallo, \n Ik ben Ido"} />
          </h1>
          <code className="fadeInBottom">
            &lt;p&gt; Fullstack ontwikkelaar &lt;/p&gt;
          </code>
          <br />
          {/* <button className="contactButton"> Lorem Ipsum </button> */}
        </div>
        {/* <div className="scrollDownLeft">
          <p className="left"> Scroll down </p>
        </div>
        <div className="scrollDownRight">
          <p className="right"> Scroll down </p>
        </div> */}
      {/* <Footer fixed="fixed"/> */}
      </div>
    );
  }
}
export default HomePage;
