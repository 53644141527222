import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import WatchProject from "./../../svg/WatchProject.svg";
import Footer from "../../Components/Footer/Footer.jsx"
import "./Portfolio.scss";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        {
          title: "",
          background: "https://idorutjes.nl/static/media/App%201.376f3ad1.png",
          description:
            "DVMobile voor Futura 4 Retail toont u de realtime omzet-, budget- en klantentellingsgegevens van uw volledige bedrijf",
          link: "https://play.google.com/store/apps/details?id=nl.deviske.f4rmobile&hl=en_US&gl=US",
        },
        {
          title: "",
          background: "https://idorutjes.nl/static/media/gsn.22d8d5c1.png",
          description:
            "Gesnappt is ontwikkeld om criminaliteit in de binnenstenden van Nederland in kaart te brengen. Dit wordt gedaan aan de hand van beschrijvingen van de criminaliteit.",
          link: "",
        },
        {
          title: "",
          background:
            "https://idorutjes.nl/static/media/sustainable%20charging.c0dc8471.png",
          description:
            "De HAN heeft met verschillende bedrijven een systeem ontwikkeld voor het opladen van elektrische auto's. Dit systeem kende alleen een achterkant en de voorkant moest ontwikkeld worden. Sinds 2020 wordt deze applicatie niet meer ondersteund door de HAN. Redenen onbekend.",
          link: "",
        },
        {
          title: "",
          background:
            "https://idorutjes.nl/static/media/no_photography_FILL0_wght400_GRAD0_opsz48.svg",
          description:
            "Inzicht is een tool waarmee data wordt geanalsyeerd van water afvoersystemen zoals H2gO en Aquaview. Dit wordt mede gedaan door deze data te Standaardiseren.",
          link: "https://www.linqiot.nl/inzicht",
        },
      ],
      selectedProject: {},
      disabled: true,
      titleAnimationDone : false
    };
  }

  openPopup(id, event) {
    this.setState({
      ...this.state,
      disabled: false,
      selectedProject: this.state.projects[id],
      titleAnimationDone : true
    });
  }

  closePopup() {
    this.setState({ ...this.state, disabled: true, selectedProject: {}, titleAnimationDone : true });
  }

  render() {
    return (
      <div id="portfolio">
        {this.state.disabled}
        <section>
          <PageTitle animation={this.state.titleAnimationDone} delay={0} displayText="Portfolio" />
          <p>
            In de afgelopen jaren heb ik aan allerlei verschillende projecten
            gewerkt voor mijn studie en privé. Hieronder zijn enkele van mijn
            projecten te vinden.
          </p>
        </section>
        <section className="container">
          {this.state.projects.map((object, i) => (
            <div
              onClick={this.openPopup.bind(this, i)}
              obj={object}
              key={"portfolio" + i}
              className="backgroundImage"
              style={{ backgroundImage: `url(${object.background})` }}
            >
              <section className="mouseHoverEvent">
                <div className="hoverbox"></div>
                <img
                  onClick={this.openPopup.bind(this, i)}
                  className="viewProject"
                  src={WatchProject}
                  alt="LinkedIn logo"
                />
              </section>
            </div>
          ))}
        </section>
        <div
          className="popupBackground"
          disabled={this.state.disabled}
          onClick={this.closePopup.bind(this)}
        ></div>

        <section className="popup" disabled={this.state.disabled}>
          <div className="popupContainer">
            <div
              className="backgroundImage"
              style={{
                backgroundImage: `url(${this.state.selectedProject.background})`,
              }}
            />
            <p>{this.state.selectedProject.description}</p>
          </div>
          <footer>
            {this.state.selectedProject.link != "" ? (
              <a href={this.state.selectedProject.link}>
                Link naar het project
              </a>
            ) : (
              <a href={this.state.selectedProject.link}>
                Geen link beschikbaar
              </a>
            )}
          </footer>
        </section>
      </div>
    );
  }
}
export default Portfolio;
