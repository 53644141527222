import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import LinkedIn from "../LinkedIn/LinkedIn";
import CrossSvg from "./../../svg/Cross.svg"

import HamburgerSvg from "../../svg/Hamburger.svg";
import "./Menu.scss";
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MenuOpen: false,
      hamburgerImg : HamburgerSvg
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value }, () => {});
  }

  toggleMenu(){
    let icon = CrossSvg;
    if (this.state.MenuOpen)
    {
      icon = HamburgerSvg;
    }
    this.setState({
      MenuOpen : !this.state.MenuOpen,
      hamburgerImg : icon
    })
  }

  closeMenu(){
    this.setState({
      MenuOpen : false,
      hamburgerImg : HamburgerSvg
    })
  }

  render() {
    return (
      <div>
        <div onClick={this.toggleMenu.bind(this)} id="hamburger" className={this.state.MenuOpen ? "activeBurger" : "inactiveBurger"} >
          <img src={this.state.hamburgerImg} />
        </div>
        <div id="menu" className={this.state.MenuOpen ? "active" : "inactive"}>
          <div id="logo"></div>
          <ul>
            <li>
              <Link onClick={this.closeMenu} to="/">Home</Link>{" "}
            </li>
            <li>
              <Link onClick={this.closeMenu}  to="/portfolio">Portfolio</Link>
            </li>
            <li>
              <Link onClick={this.closeMenu}  to="/ervaring">Ervaring</Link>
            </li>
            <li>
              <Link onClick={this.closeMenu}  to="/contact">Contact</Link>
            </li>
          </ul>

          <div id="socials">
            <LinkedIn />
          </div>
        </div>
      </div>
    );
  }
}
export default Menu;
