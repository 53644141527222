import React from "react";
import LinkedInSvg from "./../../svg/LinkedIn.svg"

class LinkedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    return (
      <div id="linkedIn">
          <img src={LinkedInSvg} alt="LinkedIn logo"/>
      </div>
    );
  }
}
export default LinkedIn;
