import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import Footer from "../../Components/Footer/Footer.jsx"

import "./Experiences.scss";

class Experiences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [
        {
          title: "Fullstack ontwikkelaar",
          company: "Linqiot",
          start: "juli 2021",
          end: "heden",
          description:
            "het analyseren van data uit onderliggende sytemen en deze weergeven op het front-end ",
        },
        {
          title: "Fullstack ontwikkelaar",
          company: "a&m impact",
          start: "februari 2021",
          end: "juli 2021",
          description:
            "Craft CMS plug-in die automatisch CVE meldigen zoekt van de gebruike libraries en frameworks. Deze meldigen komen uit systemen van derde partijen",
        },
        {
          title: "Spelbegeleider",
          company: "TB-Events",
          start: "augustus 2019",
          end: "juli 2021",
          description: "Het begeleiden van spellen zoals een quiz en GPS tocht",
        },
        {
          title: "Front-end ontwikkelaar ",
          company: "Xebic onderwijs b.v.",
          start: "september 2019 ",
          end: "februari 2020",
          description:
            "Het unit testen van het front-end en onderhoud aan het authenticatie systeem",
        },
      ],
      skills: [
        {
          title: "Angular",
          percentage: "65",
          color:"#b52e31",
        },
        {
          title: "React",
          percentage: "70",
          color:"#61dbfb",
        },
        {
          title: "Backend C# en Java",
          percentage: "70",
          color:"#08fdd8",
        },
        {
          title: "HTML & CSS",
          background: "100",
          color:"#08fdd8",
        }
      ],
    };
  }

  render() {
    return (
      <div id="experiences">
        <section>
          <PageTitle delay={0} displayText="Ervaring"></PageTitle>
        </section>
        <section>
          <div className="container">
            <div className="about">
              <p>
                Vanaf mijn 15de was ik al gefascineerd van de software die
                ontwikkelaars maakte. Vanaf dat moment wist ik precies wat ik
                wou doen en dat was software ontwikkelaar worden <br />
                Al vrij snel begon ik met simpele Visual Basic applicaties te
                ontwikkelen en te leren. <br />
                <br />
                Toen ik van mijn VMBO opleiding geslaagd was ben ik begonnen aan
                de opleiding applicatie ontwikkelaar op het Graafschap college.
                Tijdens deze opleiding heb ik kennis gemaakt met HTML 5, CSS 3,
                C#, Java, SQL en PHP.
                <br />
                Was mij het meest interesseerde was PHP, HTML 5 en CSS 3. In
                deze technieken heb ik mij verder verdiept in 2017 toen ik
                beogen op de HAN aan de opleiding ICT met de keuze richting
                webdevelopment. <br />
                Hier heb ik mij verdiept in verschillende webtechnieken zoals
                Javascript, ReactJS, NodeJS, c#, Java, Azure en AWS. <br />
                <br />
                Na mijn afstuderen ben ik direct aan de aan het werk gegaan bij
                Linqiot waar ik op dit moment nog steeds werk.
              </p>
            <section>
              {this.state.skills.map((skill, i) => (
                  <div> <h1>{skill.title}</h1>
                  <div className="progressBackground">
                      <div style={{width: skill.percentage + "%", backgroundColor: skill.color}} className="progress"> </div>
                  </div> 
                  </div>
              ))}
</section>  
            </div>
            <div className="experience container">
              {this.state.jobs.map((job, i) => (
                <div className="experienceJobCard">
                  <h1> {job.title} </h1>
                  <p>
                    {job.company}
                    <br />
                    {job.start} - {job.end}
                  </p>
                  <p>{job.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <Footer fixed="fixed"/> */}
      </div>
    );
  }
}
export default Experiences;
