import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import Footer from "../../Components/Footer/Footer.jsx";
import { SendMail } from "./../../Functions/Networking/HttpHandler";

import "./Contact.scss";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      body: null,
      stopAnimation : false,
      mailResponse : ""
    };
  }

  handleChange(event) {
    console.log(event);
    this.setState({ ...this.state, [event.target.name]: event.target.value, stopAnimation : false });
  }

  send = async () => {
    this.setState({ ...this.state,  mailResponse : "Bezig met het verzenden van de mail!" });

    var result = await SendMail(this.state.email,this.state.name, this.state.body);
    console.log(result);
    this.setState({ ...this.state,  mailResponse : result });

  };

  render() {
    return (
      <div id="Contact">
        <section>
          <PageTitle delay={0} animation={this.state.stopAnimation} displayText="Contact" />
          <p>{this.state.mailResponse}</p>
          <form>
            <input
              value={this.state.name}
              onChange={this.handleChange.bind(this)}
              name="name"
              type="text"
              placeholder="Naam"
            />
            <input
              name="email"
              value={this.state.email}
              onChange={this.handleChange.bind(this)}
              type="email"
              placeholder="email"
            />
            <textarea
              name="body"
              value={this.state.body}
              onChange={this.handleChange.bind(this)}
              placeholder="Bericht"
            ></textarea>
            <div className="button" onClick={this.send}>
              <p>Send message!</p>
              <div className="slider"></div>
            </div>
          </form>
        </section>
        {/* <Footer fixed="fixed"/> */}
      </div>
    );
  }
}
export default Contact;
