import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Menu from "./Components/Menu/Menu.jsx";
//Import the pages
import HomePage from "./Pages/Home/Home.jsx";
import Portfolio from "./Pages/Portfolio/Portfolio.jsx";
import Experiences from "./Pages/Experiences/Experiences.jsx";
import Contact from "./Pages/Contact/Contact.jsx";
import Header from "./Components/Header/Header.jsx"
import Footer from "./Components/Footer/Footer.jsx"

import "./App.scss";

function App() {
  return (
    <div className="App">
        <Router>
      <div id="menUContainer">
      <Menu />
      </div>
      <div id="contentContainer">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/ervaring" element={<Experiences />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      
     
      </div>

      </Router>
    </div>
  );
}

export default App;
