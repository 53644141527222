import React from "react";
import "./Footer.scss";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        // <div id={"footer"} className={this.props.fixed}>
        //   <span class="tags top-tags"> &lt;/body&gt; </span>
        //   <span class="tags top-tags"> &lt;/html&gt; <br/> </span>
        // </div>
<div class="footer">This will always appear at the bottom of the page, but <strong>not fixed</strong>.</div>
    );
  }
}
export default Footer;
