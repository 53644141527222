export async function SendMail(email, name, body){
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append( "Access-Control-Allow-Origin", '*');

  var raw = JSON.stringify({"Mail":email,"Subject":name,"Body":body});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return await fetch("/mail.php", requestOptions)
    .then(response => response.json())
    .then(result => {
      return messageBox(result)
    })
    .catch(error => {
      return "Er is een overwachte fout opgetreden!"
    });
    
}

const messageBox = (status) => {
  if (status.errorCode == 400)
  {
    return status.errorMessage;
  }
  else
  {
    return "Email is verzonden";
  }
}