import React from "react";
import "./Header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        <div id="header">
          <span class="tags top-tags"> &lt;html&gt; <br/> </span>
          <span class="tags top-tags"> &lt;body&gt; <br/> </span>
        </div>
    );
  }
}
export default Header;
